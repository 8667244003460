import React from "react";
import Layout from "../../components/Layout";
import headerImg from "../../images/shield.png";
import { Helmet } from "react-helmet";
import favIcon from '../../images/FosterTalent_fav_icon.png';
import "./privacy-policy.scss";

function PrivacyPolicyComponent() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Foster Talent</title>
        <link rel="icon" type="image/x-icon" href={favIcon} />
      </Helmet>
      <Layout>
        <div className="privacyContainer">
          <div className="privacyPolicy">
            <div className="privacyHeader">
              <h2>Privacy Policy</h2>
              <img src={headerImg} />
            </div>

            <div className="privacyBody">
              <div className="boxSection">
                <h2>Summary of our Privacy Policy</h2>
                <p>
                  It covers every theSense website that links here and all of the
                  products and services (Foster Talent Recruiter Experience, Foster Talent
                  Candidate Experience, and Foster) contained on those websites.
                  The detailed policy follows the same structure as this summary
                  and constitutes the actual legal document.
                </p>

                <p>
                  Our privacy commitment: theSense has never sold your information
                  to someone else for advertising or made money by showing you
                  other people's ads, and we never will. This policy tells you
                  what information we collect from you, what we do with it, who
                  can access it, and what you can do about it.
                </p>

                <h3>Last updated on: 10th October 2024.</h3>
              </div>

              <div className="boxSection">
                <h2>Part I – Information theSense collects and controls</h2>

                <p>
                  We only collect the information that we actually need. Some of
                  that is information that you actively give us when you sign up
                  for an account, register for an event, ask for customer support,
                  or buy something from us. We store your name and contact
                  information, but we don't store credit card numbers.
                </p>

                <p>
                  When you visit one of our websites or use our software, we
                  automatically log some basic information like how you got to the
                  site, where you navigated within it, and what features and
                  settings you use. We use this information to improve our
                  websites and services and to drive new product development.
                </p>

                <p>
                  Sometimes we receive information indirectly. If you ask about
                  our products through one of our referral programs or reselling
                  partners, or sign in to one of our products through an
                  authentication service provider like LinkedIn or Google, they'll
                  pass on your contact information to us. We'll use that
                  information to complete the request that you made. If you engage
                  with our brand on social media (for instance, liking,
                  commenting, retweeting, mentioning, or following us), we'll have
                  access to your interactions and profile information. We'll still
                  have that information even if you later remove it from the
                  social media site.
                </p>
              </div>

              <div className="boxSection">
                <h2>What we do with your information</h2>

                <p>
                  We use your information to provide the services you've
                  requested, create and maintain your accounts, and keep an eye
                  out for unauthorized activity on your accounts. We also use it
                  to communicate with you about the products you're currently
                  using, your customer support requests, new products you may
                  like, chances for you to give us feedback, and policy updates.
                  We analyze the information we collect to understand user needs
                  and to improve our websites and services.
                </p>

                <p>
                  We're required to have a legal basis for collecting and
                  processing your information. In most cases, we either have your
                  consent or need the information to provide the service you've
                  requested from us. When that's not the case, we must demonstrate
                  that we have another legal basis, such as our legitimate
                  business interests.
                </p>

                <p>
                  You can decline certain kinds of information use either by not
                  providing the information in the first place or by opting out
                  later. You can also disable cookies to prevent your browser from
                  giving us information, but if you do so, certain website
                  features may not work properly. We completely disable
                  non-essential and intrusive third-party cookies from all
                  theSense websites and products.
                </p>

                <p>
                  We limit access to your personal information to our employees
                  and contractors who have a legitimate need to use it. If we
                  share your information with other parties (like developers,
                  service providers, domain registrars, and reselling partners),
                  they must have appropriate security measures and a valid reason
                  for using your information, typically to serve you.
                </p>

                <p>
                  The European Economic Area (EEA) provides certain rights to data
                  subjects (including access, rectification, erasure, restriction
                  of processing, data portability, and the right to object and to
                  complain). theSense undertakes to provide you the same rights no
                  matter where you choose to live.
                </p>

                <p>
                  We keep your personal information for as long as it is required
                  for the purposes stated in this Privacy Policy. When we no
                  longer have a legitimate need to process your information, we
                  will delete, anonymize, or isolate your information, whichever
                  is appropriate.
                </p>
              </div>

              <div className="boxSection">
                <h2>
                  Part II – Information that theSense processes on your behalf
                </h2>

                <p>
                  If you handle other people's data using theSense apps, such as
                  information about your customers or employees, you are
                  entrusting that data to us for processing. The data you entrust
                  to us for processing is called service data.
                </p>

                <p>
                  You own your service data. We protect it, limit access to it,
                  and only process it according to your instructions. You may
                  access it, share it through third-party integrations, and
                  request that we export or delete it.
                </p>

                <p>
                  We hold the data in your account as long as you choose to use
                  theSense Services. After you terminate your account, your data
                  will be automatically deleted from our active database within 12
                  months and from our backups within 6 months after that.
                </p>

                <p>
                  If you are in the European Economic Area and you believe that
                  someone has entrusted your information to us for processing (for
                  instance, your employer or a company whose services you use),
                  you can request certain actions from us regarding your data. To
                  exercise those data rights, please contact the person or company
                  that entrusted the data to us and we will work with them on your
                  request.
                </p>
              </div>

              <div className="boxSection">
                <h2>Part III – General</h2>

                <p>
                  Third-party websites and social media widgets have their own
                  separate privacy policies. Always check the relevant privacy
                  policy before sharing personal information with third parties.
                </p>

                <p>
                  You can always contact us to: ask questions about our privacy
                  practices, request a GDPR-compliant Data Processing Addendum,
                  alert us if you believe we have collected personal data from a
                  minor, or ask to have your personal information removed from our
                  blogs or forums. You can also check our Security Policy and
                  Privacy Policy
                </p>

                <p>
                  We will contact you to let you know if we make any major changes
                  to our privacy policy, or in the highly unlikely event that we
                  ever decide to sell our business.
                </p>
              </div>

              <div className="boxSection">
                <h1>Privacy Policy</h1>

                <h3>theSense Privacy Commitment</h3>

                <p>
                  "theSense", proudly brought to you by SmartSense Consulting Solutions, takes privacy seriously—and we’ve got the ISO/IEC 27001:2022 certification to prove it! This international standard for Information Security, Cybersecurity, and Privacy Protection is baked into everything we do. Unless you’ve got specific requirements on your end, our default data protection policies are always on, ensuring top-notch technical and organizational safeguards for privacy. At "theSense", we live and breathe “Data Protection by Design and by Default”—it’s not just a policy, it’s our vibe. As the Controller, you’re in the driver’s seat when it comes to kicking off any necessary data processing agreements to keep your end customers’ privacy locked down. Let’s make privacy protection a team effort!
                </p>
                <p>
                  theSense has worried about customer and user privacy, long
                  before it became fashionable, politically correct, or legally
                  binding to take such a position. We ask for only the least
                  amount of information necessary, gathering only what we believe
                  is essential for doing business, or for the specific transaction
                  at hand. We let customers know the information we have on them
                  and allow them to opt-out of specific engagements. But, by far,
                  our biggest commitment is that we do not make a single dollar
                  from advertising revenue. This means we avoid the fundamental
                  conflict of interest between gathering customer information and
                  fueling advertising revenue and the unavoidable compromises in
                  customer privacy that it brings.
                </p>

                <p>
                  The goal of this policy is to make explicit the information we
                  gather, how we will use it, and how we will not. This policy is
                  unfortunately longer than we would like, but we must
                  unambiguously address all the relevant cases.
                </p>

                <h3>Scope of this Privacy Policy</h3>
                <p>
                  This Privacy Policy applies to all theSense websites that link
                  to it. It also applies to the products and services provided by
                  theSense through these websites.
                </p>

                <p>This Privacy Policy is divided into three parts:</p>

                <h3>Part I – Information theSense collects and controls</h3>

                <p>
                  This part deals with how theSense collects and uses information
                  about website visitors, potential customers, users of theSense
                  products and services, and others who contact theSense through
                  forms or email addresses published on or linked to our websites.
                </p>

                <h3>
                  Part II – Information that theSense processes on your behalf
                </h3>

                <p>
                  This part deals with how theSense handles data that you entrust
                  to theSense when you use our products and services, or when you
                  share any personal or confidential information with us while
                  requesting customer support.
                </p>

                <h3>Part III – General</h3>

                <p>
                  This part deals with topics that are relevant to both Parts I
                  and II, and other general topics such as theSense's security
                  commitments and how we will inform you when we change this
                  Privacy Policy.
                </p>
              </div>

              <div className="boxSection">
                <h2>Part I – Information theSense collects and control</h2>

                <h3>What information theSense collects</h3>

                <p>
                  We collect information about you only if we need the information
                  for some legitimate purpose. theSense will have information
                  about you only if (a) you have provided the information
                  yourself, (b) theSense has automatically collected the
                  information, or (c) theSense has obtained the information from a
                  third party. Below we describe the various scenarios that fall
                  under each of those three categories and the information
                  collected in each one.
                </p>

                <p>Information that you provide us</p>

                <p>
                  <b>i. Account signup:</b> When you sign up for an account to
                  access one or more of our services, we ask for information like
                  your name, contact number, email address, company name, and
                  country to complete the account signup process. You'll also be
                  required to choose a unique username and a password for
                  accessing the created account. You may also provide us with more
                  information such as your photo, time zone, and language, but we
                  don’t require that information to sign up for an account.
                </p>

                <p>
                  <b>ii. Event registrations and other form submissions:</b> We
                  record information that you submit when you (i) register for any
                  event, including webinars or seminars, (ii) subscribe to our
                  newsletter or any other mailing list, (iii) submit a form in
                  order to download any product, whitepaper, or other materials,
                  (iv) participate in contests or respond to surveys, or (v)
                  submit a form to request customer support, get a quote or to
                  contact theSense for any other purpose.
                </p>

                <p>
                  <b>iii. Payment processing:</b> When you buy something from us,
                  we ask you to provide your name, contact information, credit
                  card information, or other payment account information. When you
                  submit your card information, we store the name and address of
                  the cardholder, the expiry date, and the last four digits of the
                  credit card number. We do not store the actual credit card
                  number.
                </p>

                <p>
                  <b>iv. Testimonials:</b> When you authorize us to post
                  testimonials about our products and services on websites, we may
                  include your name and other personal information in the
                  testimonial. You will be given an opportunity to review and
                  approve the testimonial before we post it. If you wish to update
                  or delete your testimonial, you can contact us at
                  <a href="mailto:dpo@smartsensesolutions.com">
                    dpo@smartsensesolutions.com
                  </a>
                </p>

                <p>
                  <b>v. Interactions with theSense:</b> We may record, analyze and
                  use your interactions with us, including email, telephone, and
                  chat conversations with our sales and customer support
                  professionals, for improving our interactions with you and other
                  customers.
                </p>
              </div>

              <div className="boxSection">
                <h3>Information that we collect automatically</h3>

                <p>
                  i. Information from browsers, devices, and servers: When you
                  visit our websites, we collect information that web browsers,
                  mobile devices, and servers make available, such as the internet
                  protocol address, browser type, language preference, time zone,
                  referring URL, date and time of access, operating system, mobile
                  device manufacturer and mobile network information. We include
                  these in our log files to understand more about visitors to our
                  websites.
                </p>

                <p>
                  ii. Information from cookies and tracking technologies: We use
                  temporary and permanent cookies to identify users of our
                  services and to enhance user experience. We embed unique
                  identifiers in our downloadable products to track the usage of
                  the products. We also use cookies, beacons, tags, scripts, and
                  other similar technologies to identify visitors, track website
                  navigation, gather demographic information about visitors and
                  users, understand email campaign effectiveness, and for targeted
                  visitor and user engagement by tracking your activities on our
                  websites. We mostly use first-party cookies and do not use
                  third-party cookies or other third-party tracking technologies
                  on our websites for non-essential or intrusive tracking. We also
                  use first-party Local Storage Objects (LSOs) such as HTML5 to
                  store content information and preferences to provide certain
                  features.
                </p>

                <p>
                  iii. Information from application logs and mobile analytics: We
                  collect information about your use of our products, and services
                  from application logs, and in-house usage analytics tools, and
                  use it to understand how your use and needs can improve our
                  products. This information includes clicks, scrolls, features
                  accessed, access time and frequency, errors generated,
                  performance data, storage utilized, user settings and
                  configurations, and devices used to access and their locations.
                </p>
              </div>

              <div className="boxSection">
                <h3>Information that we collect from third parties</h3>

                <p>
                  i. Signups using federated authentication service providers: You
                  can log in to theSense Services using supported federated
                  authentication service providers such as LinkedIn, Microsoft,
                  and Google. These services will authenticate your identity and
                  give you the option to share certain personal information with
                  us, such as your name and email address.
                </p>

                <p>
                  ii. Referrals: If someone has referred any of our products or
                  services to you through any of our referral programs, that
                  person may have provided us with your name, email address, and
                  other personal information. You may contact us at
                  <a href="mailto:dpo@smartsensesolutions.com">
                    dpo@smartsensesolutions.com
                  </a>
                  to request that we remove your information from our database. If
                  you provide us information about another person, or if another
                  person gives us your information, we will only use that
                  information for the specific reason for which it was provided to
                  us.
                </p>

                <p>
                  iii. Information from our reselling partners and service
                  providers: If you contact any of our reselling partners, or
                  otherwise express interest in any of our products or services to
                  them, the reselling partner may pass your name, email address,
                  company name, and other information to theSense. If you register
                  for or attend an event that is sponsored by theSense, the event
                  organizer may share your information with us. theSense may also
                  receive information about you from review sites if you comment
                  on any review of our products and services, and from other
                  third-party service providers that we engage for marketing our
                  products and services.
                </p>

                <p>
                  iv. Information from social media sites and other publicly
                  available sources: When you provide feedback or reviews about
                  our products, interact, or engage with us on marketplaces,
                  review sites, or social media sites such as Facebook, Twitter,
                  LinkedIn, and Instagram through posts, comments, questions, and
                  other interactions, we may collect such publicly available
                  information, including profile information, to allow us to
                  connect with you, improve our products, better understand user
                  reactions and issues, or to reproduce and publish your feedback
                  on our websites. We must tell you that once collected, this
                  information may remain with us even if you delete it from these
                  sites. theSense may also add and update information about you,
                  from other publicly available sources.
                </p>
              </div>

              <div className="boxSection">
                <h2>Purposes for using information</h2>

                <p>
                  In addition to the purposes mentioned above, we may use your
                  information for the following purposes:
                </p>

                <ul>
                  <li>
                    To communicate with you (such as through email) about products
                    that you have downloaded and services that you have signed up
                    for, changes to this Privacy Policy changes to the Terms of
                    Service, or important notices;
                  </li>

                  <li>
                    To keep you posted on new products and services, upcoming
                    events, offers, promotions and other information that we think
                    will be of interest to you;
                  </li>

                  <li>
                    To ask you to participate in surveys, or to solicit feedback
                    on our products and services;
                  </li>

                  <li>
                    To set up and maintain your account, and to do all other
                    things required for providing our services, such as enabling
                    collaboration, providing website and mail hosting, and backing
                    up and restoring your data;
                  </li>

                  <li>
                    To understand how users use our products and services, to
                    monitor and prevent problems, and to improve our products and
                    services;
                  </li>

                  <li>
                    To provide customer support, and to analyze and improve our
                    interactions with customers;
                  </li>

                  <li>
                    To detect and prevent fraudulent transactions and other
                    illegal activities, to report spam, and protect the rights and
                    interests of theSense, theSense’s users, third parties, and
                    the public;
                  </li>

                  <li>
                    To update, expand and analyze our records, identify new
                    customers, and provide products and services that may be of
                    interest to you;
                  </li>

                  <li>
                    To analyze trends, administer our websites, and track visitor
                    navigations on our websites to understand what visitors are
                    looking for and to better help them;
                  </li>

                  <li>
                    To monitor and improve marketing campaigns and make
                    suggestions relevant to the user.
                  </li>
                </ul>
              </div>

              <div className="boxSection">
                <h2>Legal bases for collecting and using information</h2>

                <p>
                  Legal processing bases applicable to theSense: If you are an
                  individual from the European Economic Area (EEA), our legal
                  basis for information collection and use depends on the personal
                  information concerned and the context in which we collect it.
                  Most of our information collection and processing activities are
                  typically based on (i) contractual necessity, (ii) one or more
                  legitimate interests of theSense or a third party that is not
                  overridden by your data protection interests, or (iii) your
                  consent. Sometimes, we may be legally required to collect your
                  information or may need your personal information to protect
                  your vital interests or those of another person.
                </p>

                <p>
                  Withdrawal of consent: Where we rely on your consent as a legal
                  basis, you have the right to withdraw your consent at any time,
                  but this will not affect any processing that has already taken
                  place.
                </p>

                <p>
                  Legitimate interests notice: Where we rely on legitimate
                  interests as the legal basis and those legitimate interests are
                  not specified above, we will clearly explain to you what those
                  legitimate interests are at the time that we collect your
                  information.
                </p>
              </div>

              <div className="boxSection">
                <h2>Your choice in information use</h2>

                <p>
                  Opt-out of non-essential electronic communications: You may opt
                  out of receiving newsletters and other non-essential messages by
                  using the ‘unsubscribe’ function included in all such messages.
                  However, you will continue to receive essential notices and
                  emails such as account notification emails (password change,
                  renewal reminders, etc.), security incident alerts, security and
                  privacy update notifications, and essential transactional and
                  payment-related emails.
                </p>

                <p>
                  Disable cookies: You can disable browser cookies before visiting
                  our websites. However, if you do so, you may not be able to use
                  certain features of the websites properly.
                </p>

                <p>
                  Optional information: You can choose not to provide optional
                  profile information such as your photo. You can also delete or
                  change your optional profile information. You can always choose
                  not to fill in non-mandatory fields when you submit any form
                  linked to our websites.
                </p>
              </div>

              <div className="boxSection">
                <h2>Who we share your information with</h2>

                <p>
                  All theSense entities have access to the information covered in
                  Part I. We do not sell any personal information. We share your
                  information only in the ways that are described in this Privacy
                  Policy, and only with parties who adopt appropriate
                  confidentiality and security measures.
                </p>

                <p>
                  Employees and independent contractors: Employees and independent
                  contractors of relevant theSense entities have access to the
                  information covered in Part I on a need-to-know basis. We
                  require all employees and independent contractors of theSense
                  entities to follow this Privacy Policy for personal information
                  that we share with them.
                </p>

                <p>
                  Third-party service providers: We may need to share your
                  personal information and aggregated or de-identified information
                  with third-party service providers that we engage, such as
                  marketing and advertising partners, event organizers, web
                  analytics providers, and payment processors. These service
                  providers are authorized to use your personal information only
                  as necessary to provide these services to us.
                </p>

                <p>
                  Domain registrars: When you register a domain through theSense
                  from domain name registrars, we share your name and contact
                  information such as your physical address, email address, and
                  phone number with them as per the ICANN domain registration
                  rules.
                </p>

                <p>
                  Reselling partners: We may share your personal information with
                  our authorized reselling partners in your region, solely for the
                  purpose of contacting you about products that you have
                  downloaded or services that you have signed up for. We will give
                  you an option to opt out of continuing to work with that
                  partner.
                </p>

                <p>
                  Other cases: Other scenarios in which we may share the same
                  information covered under Parts I and II are described in Part
                  III.
                </p>
              </div>

              <div className="boxSection">
                <h2>
                  Your rights with respect to the information we hold about you as
                  a controller
                </h2>

                <p>
                  If you are in the European Economic Area (EEA), you have the
                  following rights with respect to information that theSense holds
                  about you. theSense undertakes to provide you with the same
                  rights no matter where you choose to live.
                </p>

                <p>
                  Right to access: You have the right to access (and obtain a copy
                  of, if required) the categories of personal information that we
                  hold about you, including the information's source, purpose and
                  period of processing, and the persons to whom the information is
                  shared
                </p>

                <p>
                  Right to rectification: You have the right to update the
                  information we hold about you or to rectify any inaccuracies.
                  Based on the purpose for which we use your information, you can
                  instruct us to add supplemental information about you to our
                  database.
                </p>

                <p>
                  Right to erasure: You have the right to request that we delete
                  your personal information in certain circumstances, such as when
                  it is no longer necessary for the purpose for which it was
                  originally collected.
                </p>

                <p>
                  Right to restriction of processing: You may also have the right
                  to request to restrict the use of your information in certain
                  circumstances, such as when you have objected to our use of your
                  data but we need to verify whether we have overriding legitimate
                  grounds to use it.
                </p>

                <p>
                  Right to data portability: You have the right to transfer your
                  information to a third party in a structured, commonly used, and
                  machine-readable format, in circumstances where the information
                  is processed with your consent or by automated means.
                </p>

                <p>
                  Right to object: You have the right to object to the use of your
                  information in certain circumstances, such as the use of your
                  personal information for direct marketing.
                </p>

                <p>
                  Right to complain: You have the right to complain to the
                  appropriate supervisory authority if you have any grievance
                  against the way we collect, use, or share your information. This
                  right may not be available to you if there is no supervisory
                  authority dealing with data protection in your country.
                </p>
              </div>

              <div className="boxSection">
                <h2>Retention of information</h2>

                <p>
                  We retain your personal information for as long as it is
                  required for the purposes stated in this Privacy Policy.
                  Sometimes, we may retain your information for longer periods as
                  permitted or required by law, such as to maintain suppression
                  lists, prevent abuse, if required in connection with a legal
                  claim or proceeding, to enforce our agreements, for tax,
                  accounting, or to comply with other legal obligations. When we
                  no longer have a legitimate need to process your information, we
                  will delete or anonymize your information from our active
                  databases. We will also securely store the information and
                  isolate it from further processing on backup discs until
                  deletion is possible.
                </p>
              </div>

              <div className="boxSection">
                <h2>
                  Part II – Information that theSense processes on your behalf
                </h2>

                <h3>Information entrusted to theSense and purpose</h3>

                <p>
                  Information provided in connection with services: You may
                  entrust information that you or your organization (“you”)
                  control, to theSense in connection with the use of our services
                  or for requesting technical support for our products. This
                  includes information regarding your customers and your employees
                  (if you are a controller) or data that you hold and use on
                  behalf of another person for a specific purpose, such as a
                  customer to whom you provide services (if you are a processor).
                  The data may either be stored on our servers when you use our
                  services, or transferred or shared with us as part of a request
                  for technical support or other services.
                </p>

                <p>
                  (All the information entrusted to theSense is collectively
                  termed “service data”)
                </p>

                <h3>Ownership and control of your service data</h3>

                <p>
                  We recognize that you own your service data. We provide you
                  complete control of your service data by providing you the
                  ability to (i) access your service data, (ii) share your service
                  data through supported third-party integrations, and (iii)
                  request export or deletion of your service data.
                </p>

                <h3>Push notifications</h3>

                <p>
                  If you have enabled notification on our desktop applications, we
                  will push notifications through a push notification provider
                  such as Apple Push Notification Service, Google Cloud Messaging,
                  or Windows Push Notification Services. You can manage your push
                  notification preferences or deactivate these notifications by
                  turning off notifications in the application or device settings.
                </p>

                <h3>Who we share service data with</h3>

                <p>
                  theSense and third-party sub-processors: In order to provide
                  services and technical support for our products, the contracting
                  entity within theSense engages other group entities and third
                  parties.
                </p>

                <p>
                  Employees and independent contractors: We may provide access to
                  your service data to our employees and individuals who are
                  independent contractors of the theSense entities involved in
                  providing the services (collectively our “employees”) so that
                  they can (i) identify, analyze and resolve errors, (ii) manually
                  verify emails reported as spam to improve spam detection, or
                  (iii) manually verify scanned images that you submit to us to
                  verify the accuracy of optical character recognition. We ensure
                  that access by our employees to your service data is restricted
                  to specific individuals, and is logged and audited. Our
                  employees will also have access to data that you knowingly share
                  with us for technical support or to import data into our
                  products or services. We communicate our privacy and security
                  guidelines to our employees and strictly enforce privacy
                  safeguards within the theSense group.
                </p>

                <p>
                  Collaborators and other users: Some of our products or services
                  allow you to collaborate with other users or third parties.
                  Initiating collaboration may enable other collaborators to view
                  some or all of your profile information. For example, When you
                  integrate foster-talent with Google meet, we share enterprise data
                  needed for the google meet integration.
                </p>

                <p>
                  Third-party integrations you have enabled: Most of our products
                  and services support integrations with third-party products and
                  services. If you choose to enable any third-party integrations,
                  you may be allowing a third party to access your service data
                  and personal information about you. We encourage you to review
                  the privacy practices of the third-party services and products
                  before you enable integrations with them.
                </p>

                <p>
                  Other cases: Other scenarios in which we may share information
                  that is common to information covered under Parts I and II are
                  described in Part III.
                </p>

                <h3>Retention of information</h3>

                <p>
                  We hold the data in your account as long as you choose to use
                  theSense Services. Once you terminate your theSense user
                  account, your data will eventually get deleted from the active
                  database during the next clean-up that occurs once in 12 months.
                  The data deleted from the active database will be deleted from
                  backups after 6 months.
                </p>

                <h3>Data subject requests</h3>

                <p>
                  If you are from the European Economic Area and you believe that
                  we store, use or process your information on behalf of one of
                  our customers, please contact the customer if you would like to
                  access, rectify, erase, restrict or object to processing, or
                  export your personal data. We will extend our support to our
                  customers in responding to your request within a reasonable
                  timeframe.
                </p>
              </div>

              <div className="boxSection">
                <h2>Part III – General</h2>

                <h3>How secure is your information</h3>

                <p>
                  We have taken steps to implement appropriate administrative,
                  technical & physical safeguards to prevent unauthorized access,
                  use, modification, disclosure, or destruction of the information
                  you entrust to us. If you have any concerns regarding the
                  security of your data, we encourage you to check our Security
                  Policy or write to us at security@thesensexxx.com with any
                  questions.
                </p>

                <h3>Locations and international transfers</h3>

                <p>
                  We share your personal information and service data within the
                  theSense Group and with third parties engaged by theSense Group
                  for the purposes described above. By accessing or using our
                  products and services or otherwise providing personal
                  information or service data to us, you understand that the
                  processing, transfer, and storage of your personal information
                  or Service Data within the United States of America, the
                  European Economic Area (EEA) and other countries where theSense
                  operates. Such transfers are subject to appropriate data
                  protection agreements such as a group company agreement that is
                  based on EU Commission’s Model Contractual Clauses for data
                  processing activities to which GDPR applies. You can write to{" "}
                  <a href="mailto:dpo@smartsensesolutions.com">
                    dpo@smartsensesolutions.com
                  </a>{" "}
                  for obtaining a copy of the agreements on the basis of which we
                  transfer your data within theSense Group and with the third
                  parties engaged by us.
                </p>

                <h3>Data processing addendum</h3>

                <p>
                  To enable you to be compliant with your data protection
                  obligations, we are prepared to sign an appropriate Data
                  Processing Addendum (DPA). You can request a DPA from theSense
                  by completing this form. Once we get your request, we'll forward
                  the DPA to you for your signature.
                </p>

                <h3>Automation and Artificial Intelligence</h3>

                <p>
                  In order to provide enhanced productivity and predictive
                  capabilities to our users, we employ a variety of technologies
                  such as NLP, regex parsing, template matching, artificial
                  intelligence, and machine learning. In keeping with theSense's
                  promise not to exploit your data in a way that is not respectful
                  of your privacy and confidentiality expectations, we make only
                  the following limited use of service data for these
                  technologies: (i) using anonymized crops of service data to
                  improve the accuracy of the algorithms; and (ii) using your
                  organization's data for developing models specific for your
                  organization. Our automation and artificial intelligence
                  technologies are mostly powered by our own organization's data
                  such as internal communications, communications with customers,
                  and internal documents as well as free and paid external
                  sources.
                </p>

                <h3>Do Not Track (DNT) requests</h3>

                <p>
                  Some internet browsers have enabled 'Do Not Track’ (DNT)
                  features, which send out a signal (called the DNT signal) to the
                  websites that you visit indicating that you don't wish to be
                  tracked. Currently, there is no standard that governs what
                  websites can or should do when they receive these signals. For
                  now, we do not take action in response to these signals.
                </p>

                <h3>External links on our websites</h3>

                <p>
                  Some pages of our websites may contain links to websites that
                  are not linked to this Privacy Policy. If you submit your
                  personal information to any of these third-party sites, your
                  personal information is governed by their privacy policies. As a
                  safety measure, we recommend that you not share any personal
                  information with these third parties unless you've checked their
                  privacy policies and assured yourself of their privacy
                  practices.
                </p>

                <h3>Blogs and forums</h3>

                <p>
                  We offer publicly accessible blogs and forums on our websites.
                  Please be aware that any information you provide on these blogs
                  and forums may be used to contact you with unsolicited messages.
                  We urge you to be cautious in disclosing personal information in
                  our blogs and forums. theSense is not responsible for the
                  personal information you elect to disclose publicly. Your posts
                  and certain profile information may remain even after you
                  terminate your account with theSense. To request the removal of
                  your information from our blogs and forums, you can contact us
                  at
                  <a href="mailto:dpo@smartsensesolutions.com">
                    dpo@smartsensesolutions.com
                  </a>
                </p>

                <h3>Social media widgets</h3>

                <p>
                  Our websites include social media widgets such as Facebook
                  "like" buttons and Twitter "tweet" buttons that let you share
                  articles and other information. These widgets may collect
                  information such as your IP address and the pages you navigate
                  on the website and may set a cookie to enable the widgets to
                  function properly. Your interactions with these widgets are
                  governed by the privacy policies of the companies providing
                  them.
                </p>

                <h3>Disclosures in compliance with legal obligations</h3>

                <p>
                  We may be required by law to preserve or disclose your personal
                  information and service data to comply with any applicable law,
                  regulation, legal process, or governmental request, including to
                  meet national security requirements.
                </p>

                <h3>Enforcement of our rights</h3>

                <p>
                  We may disclose personal information and service data to a third
                  party if we believe that such disclosure is necessary for
                  preventing fraud, spam filtering, investigating any suspected
                  illegal activity, enforcing our agreements or policies, or
                  protecting the safety of our users.
                </p>

                <h3>Business Transfers</h3>

                <p>
                  We do not intend to sell our business. However, in the unlikely
                  event that we sell our business or get acquired or merged, we
                  will ensure that the acquiring entity is legally bound to honor
                  our commitments to you. We will notify you via email or through
                  a prominent notice on our website of any change in ownership or
                  in the use of your personal information and service data. We
                  will also notify you about any choices you may have regarding
                  your personal information and service data.
                </p>

                <h3>Disclosure</h3>

                <p>
                  Foster use and transfer to any other app of
                  information received from Google APIs will
                  adhere to{" "}
                  <a
                    target="_blank"
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  >
                    Google API Services User Data Policy
                  </a>
                  , including the Limited Use requirements.
                </p>

                <h3>Compliance with this Privacy Policy</h3>

                <p>
                  We make every effort, including periodic reviews, to ensure that
                  the personal information you provide is used in conformity with
                  this Privacy Policy. If you have any concerns about our
                  adherence to this Privacy Policy or the manner in which your
                  personal information is used, kindly write to us
                  <a href="mailto:dpo@smartsensesolutions.com">
                    dpo@smartsensesolutions.com
                  </a>
                  . We'll contact you, and if required, coordinate with the
                  appropriate regulatory authorities to effectively address your
                  concerns.
                </p>

                <h3>Notification of changes</h3>

                <p>
                  We may modify the Privacy Policy at any time, upon notifying you
                  through a service announcement or by sending an email to your
                  primary email address. If we make significant changes to the
                  Privacy Policy that affect your rights, you will be provided
                  with at least 30 days advance notice of the changes by email to
                  your primary email address. However, if you have not verified
                  your email address, you may miss important notifications that we
                  send through email. If you think that the updated Privacy Policy
                  affects your rights with respect to your use of our products or
                  services, you may terminate your use by sending us an email
                  within 30 days. Your continued use after the effective date of
                  changes to the Privacy Policy will be deemed to be your
                  agreement to the modified Privacy Policy. You will not receive
                  email notification of minor changes to the Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>

  );
}

export default PrivacyPolicyComponent;
