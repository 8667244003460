import React from "react";
import "./terms-of-service.scss";
import headerImg from "../../images/shield.png";
import Layout from "../../components/Layout/index";
import { Helmet } from "react-helmet";
import favIcon from '../../images/FosterTalent_fav_icon.png';

function TermsOfService() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Foster Talent</title>
        <link rel="icon" type="image/x-icon" href={favIcon} />
      </Helmet>
      <Layout>
        <div className="termsOfServiceContainer">
          <div className="termsOfService">
            <div className="termsHeader">
              <h1>Terms of Service - India</h1>
              <img src={headerImg} />
            </div>

            <p>
              THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
              ( HEREINAFTER “YOU” or “YOUR”) AND SMARTSENSE SOLUTIONS PVT. LTD.
              GOVERNING YOUR USE OF THESENSE ONLINE BUSINESS PRODUCTIVITY AND
              COLLABORATION SOFTWARE IF YOU ARE BASED IN INDIA. IF YOU ARE NOT
              BASED IN INDIA, OUR GLOBAL TERMS OF SERVICE WILL APPLY.
            </p>

            <h2>Parts of this Agreement</h2>

            <p>
              This Agreement consists of the following terms and conditions
              (hereinafter the “General Terms”) and terms and conditions, if
              any, specific to the use of individual Services (hereinafter the
              “Service Specific Terms”). In the event of a conflict between the
              General Terms and Service Specific Terms, the Service Specific
              Terms shall prevail.
            </p>

            <h2>Acceptance of the Agreement</h2>
            <p>
              You must be of legal age to enter into a binding agreement in
              order to accept the Agreement. If you do not agree to the General
              Terms, do not use any of our Services. If you agree to the General
              Terms and do not agree to any Service Specific Terms, do not use
              the corresponding Service. You can accept the Agreement by
              checking a checkbox or clicking on a button indicating your
              acceptance of the Agreement or by actually using the Services.
            </p>

            <h2>Description of Service</h2>
            <p>
              We provide cloud software and applications for businesses
              ("Service" or "Services"). You may use the Services for your
              personal and business use or for internal business purposes in the
              organization that you represent. You may connect to the Services
              using any Internet browser supported by the Services. You are
              responsible for obtaining access to the Internet and the equipment
              necessary to use the Services. You can create and edit content
              with your user account and if you choose to do so, you can publish
              and share such content.
            </p>

            <h2>Beta Service</h2>
            <p>
              We may offer certain Services as closed or open beta services
              ("Beta Service" or “Beta Services”) for the purpose of testing and
              evaluation. You agree that we have the sole authority and
              discretion to determine the period of time for testing and
              evaluation of Beta Services. We will be the sole judge of the
              success of such testing and the decision, if any, to offer the
              Beta Services as commercial services. You will be under no
              obligation to acquire a subscription to use any paid Service as a
              result of your subscription to any Beta Service. We reserve the
              right to fully or partially discontinue, at any time and from time
              to time, temporarily or permanently, any of the Beta Services with
              or without notice to you. You agree that theSense will not be
              liable to you or to any third party for any harm related to,
              arising out of, or caused by the modification, suspension, or
              discontinuance of any of the Beta Services for any reason.
            </p>

            <h2>Free Trial</h2>
            <p>
              If you register for a free trial of one or more Services, theSense
              will make the applicable Services available to you on a trial
              basis free of charge until the earlier (i) the end of the free
              trial period of the applicable Services (unless terminated earlier
              by you), (ii) the start date of the paid subscription period for
              the applicable Services, or (iii) termination by theSense in its
              sole discretion. Any data that you enter into the Services, and
              any customizations made to the Services during the free trial will
              be permanently lost unless you (i) purchase the corresponding paid
              subscription plan for the account, (ii) purchase applicable
              Service upgrades, or (iii) export such data before the end of the
              trial period. Notwithstanding anything contained in this Section,
              Services are offered as-is during the free trial, without any
              warranty, covenant, support, or liability whatsoever, to the
              extent permitted by law.
            </p>

            <h2>User Sign up Obligations</h2>
            <p>
              You need to sign up for a user account by providing all required
              information in order to access or use the Services. If you
              represent an organization and wish to use the Services for
              corporate internal use, we recommend that you, and all other users
              from your organization, sign up for user accounts by providing
              your corporate contact information. In particular, we recommend
              that you use your corporate email address. You agree to (i)
              provide true, accurate, current, and complete information about
              yourself as prompted by the signup process; and (ii) maintain and
              promptly update the information provided during sign-up to keep it
              true, accurate, current, and complete. If you provide any
              information that is untrue, inaccurate, outdated, or incomplete,
              or if theSense has reasonable grounds to suspect that such
              information is untrue, inaccurate, outdated, or incomplete,
              theSense may terminate your user account and refuse current or
              future use of any or all of the Services.
            </p>

            <h2>Restrictions on Use</h2>
            <p>
              In addition to all other terms and conditions of this Agreement,
              you shall not: (i) transfer the Services or otherwise make it
              available to any third party; (ii) provide any service based on
              the Services without prior written permission; (iii) allow user
              licenses to be shared or used by more than one individual other
              than by way of reassigning the user license to a new user; (iv)
              except as permitted under applicable law, attempt to disassemble,
              reverse engineer or decompile the Services; (v) use the third
              party links to sites without agreeing to their website terms &
              conditions; (vi) post links to third party sites or use their
              logo, company name, etc. without their prior written permission;
              (vii) attempt to gain unauthorized access to the Services or its
              related systems or network; (viii) use the Services in any manner
              that could damage, disable, overburden, impair or harm any server,
              network, computer system, resource of theSense; (ix) use the
              Services to send or store material containing software viruses,
              worms or other harmful computer codes, files, scripts or programs;
              (x) use the Services in any manner that interferes with or
              disrupts the integrity, security or performance of the Services,
              its components and the data contained therein; (xi) host, display,
              upload, modify, publish, transmit, store, update or share any
              information that belongs to another person or entity and to which
              you do not have any right, including personal or confidential
              information of any person or entity with respect to which you do
              not have consent or permission from such person or entity; ( xii)
              violate any applicable local, state, national or international
              law; (xiii) use the Services for any form of competitive or
              benchmarking purposes; and (xiv) remove or obscure any proprietary
              or other notices contained in the Services; (xv) use our Services
              in any manner that threatens the unity, integrity, defence,
              security or sovereignty of India, friendly relations of India with
              other countries, or public order, or causes incitement to the
              commission of any cognisable offence or prevents investigation of
              any offence or is insulting other countries; (xvi) create a false
              identity to mislead any person as to the identity or origin of any
              communication; (xvii) use the services for transmitting
              information that is patently false and untrue, and is written or
              published in any form, with the intent to mislead or harass a
              person, entity or agency for financial gain or to cause any injury
              to any person; or (xviii) use the services in a manner that
              relates to or encourages any activity prohibited by law in India.
            </p>

            <h2>Spamming and Illegal Activities</h2>
            <p>
              You agree to be solely responsible for the contents of your
              transmissions through the Services. You agree not to use the
              Services for illegal purposes or for the transmission of material
              that is unlawful, defamatory, insulting, harassing, libelous,
              invasive of another's privacy (including bodily privacy), abusive,
              threatening, harmful, vulgar, pornographic, paedophilic, harmful
              to children, obscene, racially or ethnically objectionable, or is
              otherwise objectionable, offends religious sentiments, promotes
              racism, contains viruses or malicious code, or that which
              infringes or may infringe the intellectual property or other
              rights of another. You agree not to use the Services for the
              transmission of "junk mail", "spam", "chain letters", “phishing”
              or unsolicited mass distribution of email. We reserve the right to
              terminate your access to the Services if there are reasonable
              grounds to believe that you have used the Services for any illegal
              or unauthorized activity.
            </p>

            <h2>Third-Party Applications</h2>
            <p>
              theSense Services integrate with many third-party applications
              (hereinafter "Third Party Application(s)"). Access and use of the
              Third Party Applications may require acceptance of terms of
              service and privacy policies applicable to such Third Party
              Applications (hereinafter "Third Party Terms"). You are
              responsible for reading and understanding the Third Party Terms
              before accessing or using any Third Party Application. You
              acknowledge and agree that theSense is not liable for any Third
              Party Applications. While we will try to provide you with advance
              notice, whenever reasonably possible, you acknowledge and agree
              that theSense may, at any time and in our sole discretion, and
              without any notice to you, suspend, restrict or disable access to
              or remove from theSense Services, any Third Party Application,
              without any liability to you, including without limitation for any
              loss of profits, revenue, data, goodwill or other intangible
              losses.
            </p>

            <h2>Fees and Payments</h2>
            <p>
              The Services are available under subscription plans of various
              durations. Payments for subscription plans of a duration of less
              than a year can be made only by Credit Card. Your subscription
              will be automatically renewed at the end of each subscription
              period unless you downgrade your paid subscription plan to a free
              plan or inform us that you do not wish to renew the subscription.
              At the time of automatic renewal, the subscription fee will be
              charged to the Credit Card last used by you. We provide you the
              option of changing the details if you would like the payment for
              the renewal to be made through a different Credit Card. If you do
              not wish to renew the subscription, you must inform us at least
              seven days prior to the renewal date. If you have not downgraded
              to a free plan and if you have not informed us that you do not
              wish to renew the subscription, you will be presumed to have
              authorized theSense to charge the subscription fee to the Credit
              Card last used by you.
            </p>
            <p>
              From time to time, we may change the price of any Service or
              charge for use of Services that are currently available free of
              charge. Any increase in charges will not apply until the expiry of
              your then-current billing cycle. You will not be charged for using
              any Service unless you have opted for a paid subscription plan.
            </p>
            <p>
              In the event any tax such as GST, VAT, sales tax, or the like is
              chargeable by theSense in accordance with any local, state,
              provincial, or foreign laws with respect to your subscription to
              our Services ("Taxes"), theSense will invoice you for such Taxes.
              You agree to pay theSense such Taxes in addition to the
              subscription fees. theSense shall provide you with an invoice in
              the format prescribed by the applicable local, state, provincial,
              or foreign laws to help you avail the applicable input tax credit
              for the taxes so paid.
            </p>

            <h2>Organization Accounts and Administrators</h2>
            <p>
              When you sign up for an account for your organization you may
              specify one or more administrators. The administrators will have
              the right to configure the Services based on your requirements and
              manage end users in your organization account. If your
              organization account is created and configured on your behalf by a
              third party, it is likely that such a third party has assumed an
              administrator role for your organization. Make sure that you enter
              into a suitable agreement with such a third party specifying such
              party’s roles and restrictions as an administrator of your
              organization account.
            </p>
            <p>
              You are responsible for (i) ensuring confidentiality of your
              organization account password, (ii) appointing competent
              individuals as administrators for managing your organization
              account, and (iii) ensuring that all activities that occur in
              connection with your organization account comply with this
              Agreement. You understand that theSense is not responsible for
              account administration and internal management of the Services for
              you.
            </p>
            <p>
              You are responsible for taking the necessary steps for ensuring
              that your organization does not lose control of the administrator
              accounts. You may specify a process to be followed for recovering
              control in the event of such loss of control of the administrator
              accounts by sending an email to
              <a
                href="mailto:dpo@smartsensesolutions.com"
                class="foster-color"
              >
                dpo@smartsensesolutions.com
              </a>
              , provided that the process is acceptable to theSense. In the
              absence of any specified administrator account recovery process,
              theSense may provide control of an administrator account to an
              individual by providing proof satisfactory to theSense
              demonstrating authorization to act on behalf of the organization.
              You agree not to hold theSense liable for the consequences of any
              action taken by theSense in good faith in this regard.
            </p>

            <h2>Personal Information and Privacy</h2>
            <p>
              The personal information you provide to theSense through the
              Service is governed by theSense Privacy Policy. Your election to
              use the Service indicates your acceptance of the terms of the
              theSense Privacy Policy. You are responsible for maintaining the
              confidentiality of your username, password, and other sensitive
              information. You are responsible for all activities that occur in
              your user account and you agree to inform us immediately of any
              unauthorized use of your user account by email to
              <a
                href="mailto:dpo@smartsensesolutions.com"
                class="foster-color"
              >
                dpo@smartsensesolutions.com
              </a>
              or by calling us on
              <span class="foster-color">+91-9940763752.</span> We are not
              responsible for any loss or damage to you or to any third party
              incurred as a result of any unauthorized access and/or use of your
              user account, or otherwise.
            </p>

            <h2>Communications from theSense</h2>
            <p>
              The Service may include certain communications from theSense, such
              as service announcements, administrative messages, and
              newsletters. You understand that these communications shall be
              considered part of using the Services. As part of our policy to
              provide you total privacy, we also provide you the option of
              opting out of receiving newsletters from us. However, you will not
              be able to opt out from receiving service announcements and
              administrative messages.
            </p>

            <h2>Complaints</h2>
            <p>
              If we receive a complaint from any person with respect to your
              activities as part of the use of the Services, we will forward the
              complaint to the primary email address of your user account. You
              must respond to the complainant directly within 10 days of
              receiving the complaint forwarded by us and copy theSense in the
              communication. If you do not respond to the complainant within 10
              days from the date of our email to you, we may disclose your name
              and contact information to the complainant for enabling the
              complainant to take legal action against you. You understand that
              your failure to respond to the forwarded complaint within the 10
              days time limit will be construed as your consent to the
              disclosure of your name and contact information by theSense to the
              complainant.
            </p>

            <h2>Grievance Redressal</h2>
            <p>
              In case of any grievance, the term as defined under Information
              Technology (Intermediary Guidelines and Digital Media Ethics Code)
              Rules 2021, <span class="foster-color">Data Protection Officer</span>, can be
              contacted by sending an email to
              <a
                href="mailto:dpo@smartsensesolutions.com"
                class="foster-color"
              >
                dpo@smartsensesolutions.com
              </a>.
            </p>

            <h2>Inactive User Accounts Policy</h2>
            <p>
              We reserve the right to terminate unpaid user accounts that are
              inactive for a continuous period of 120 days. In the event of such
              termination, all data associated with such user accounts will be
              deleted. We will provide you with prior notice of such termination
              and the option to back up your data. The data deletion policy may
              be implemented with respect to any or all of the Services. Each
              Service will be considered an independent and separate service for
              the purpose of calculating the period of inactivity. In other
              words, activity in one of the Services is not sufficient to keep
              your user account in another Service active. In the case of
              accounts with more than one user, if at least one of the users is
              active, the account will not be considered inactive.
            </p>

            <h2>Hosting Location</h2>
            <p>
              The location of the cloud facility from which you are served
              depends on the mapping of your region/country to the available
              cloud facilities at the time of your sign-up. We may migrate your
              account or require you to migrate your account to a different
              cloud facility in the event of any updates to the region/country
              to cloud facility mapping at any point in time. You must not mask
              your internet protocol (IP) address at the time of sign-up since
              your region/country is determined base on your IP address. If at
              any time, your actual region/country is found to be different from
              the region/country in our records, theSense may take appropriate
              action such as migrate your account or require you to migrate your
              account to the cloud facility corresponding to your
              region/country, or close your account and deny the Service to you.
            </p>

            <h2>Data Ownership</h2>
            <p>
              We respect your right to ownership of content created or stored by
              you. You own the content created or stored by you. Unless
              specifically permitted by you, your use of the Services does not
              grant theSense the license to use, reproduce, adapt, modify,
              publish or distribute the content created by you or stored in your
              user account for theSense’s commercial, marketing, or any similar
              purpose. But you grant theSense permission to access, copy,
              distribute, store, transmit, reformat, publicly display and
              publicly perform the content of your user account solely as
              required for the purpose of providing the Services to you.
            </p>

            <h2>User-Generated Content</h2>
            <p>
              You may transmit or publish content created by you using any of
              the Services or otherwise. However, you shall be solely
              responsible for such content and the consequences of its
              transmission or publication. If you are a publisher of news and
              current affairs content as defined under Information Technology
              (Intermediary Guidelines and Digital Media Ethics Code) Rules
              2021, you shall furnish details to the Ministry of Information and
              Broadcasting as required by rule 18. Any content made public will
              be publicly accessible through the internet and maybe crawled and
              indexed by search engines. You are responsible for ensuring that
              you do not accidentally make any private content publicly
              available. Any content that you may receive from other users of
              the Services is provided to you is for your information and
              personal use only and you agree not to use, copy, reproduce,
              distribute, transmit, broadcast, display, sell, license or
              otherwise exploit such content for any purpose, without the
              express written consent of the person who owns the rights to such
              content. In the course of using any of the Services, if you come
              across any content with copyright notice(s) or any copy protection
              feature(s), you agree not to remove such copyright notice(s) or
              disable such copy protection feature(s) as the case may be. By
              making any copyrighted/copyrightable content available on any of
              the Services you affirm that you have the consent, authorization,
              or permission, as the case may be from every person who may claim
              any rights in such content to make such content available in such
              manner. Further, by making any content available in the manner
              aforementioned, you expressly agree that theSense will have the
              right to block access to or remove such content made available by
              you if theSense receives complaints concerning any illegality or
              infringement of third party rights in such content. By using any
              of the Services and transmitting or publishing any content using
              such Service, you expressly consent to the determination of
              questions of illegality or infringement of third party rights in
              such content by the agent designated by theSense for this purpose.
            </p>

            <h2>Sample files and Applications</h2>
            <p>
              theSense may provide sample files and applications for the purpose
              of demonstrating the possibility of using the Services effectively
              for specific purposes. The information contained in any such
              sample files and applications consists of random data. theSense
              makes no warranty, either express or implied, as to the accuracy,
              usefulness, completeness, or reliability of the information or the
              sample files and applications.
            </p>

            <h2>Trademark</h2>
            <p>
              theSense, theSense logo, the names of individual Services, and
              their logos are trademarks of smartSense Consulting Solutions
              Private Limited. You agree not to display or use, in any manner,
              the theSense trademarks, without theSense’s prior permission.
            </p>

            <h2>Disclaimer of Warranties</h2>
            <p>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS
              AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
              AS-IS-AND-AS-AVAILABLE BASIS. THESENSE EXPRESSLY DISCLAIMS ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. THESENSE MAKES NO WARRANTY THAT
              THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
              USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
              SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
              SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
              TELEPHONE, WIRELESS DEVICE, OR DATA THAT RESULTS FROM THE USE OF
              THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
              INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM
              THESENSE, ITS EMPLOYEES, OR REPRESENTATIVES SHALL CREATE ANY
              WARRANTY NOT EXPRESSLY STATED IN THE AGREEMENT.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
              YOU AGREE THAT THESENSE SHALL, IN NO EVENT, BE LIABLE FOR ANY
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER
              LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS,
              BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS
              INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF
              OR INABILITY TO USE THE SERVICE, EVEN IF THESENSE HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL THESENSE’S
              ENTIRE LIABILITY TO YOU IN RESPECT OF ANY SERVICE, WHETHER DIRECT
              OR INDIRECT, EXCEED FIFTY THOUSAND RUPEES (₹ 50,000) OR THE FEES
              PAID BY YOU DURING THE TWELVE (12) MONTHS PRIOR TO THE FIRST EVENT
              GIVING RISE TO SUCH LIABILITY, WHICHEVER IS HIGHER.
            </p>

            <h2>Indemnification</h2>
            <p>
              ou agree to indemnify and hold harmless theSense, its officers,
              directors, employees, suppliers, and affiliates, from and against
              any losses, damages, fines, and expenses (including attorney's
              fees and costs) arising out of or relating to any claims that you
              have used the Services in violation of another party's rights, in
              violation of any law, in violations of any provisions of the
              Agreement, or any other claim related to your use of the Services,
              except where such use is authorized by theSense.
            </p>

            <h2>Governing law and Jurisdiction</h2>
            <p>
              Any controversy or claim arising out of or relating to the Terms
              shall be settled and adjudicated exclusively by the courts in
              accordance with the laws of India without regard to conflict of
              law principles.
            </p>

            <h2>Suspension and Termination</h2>
            <p>
              We may suspend your user account or temporarily disable access to
              whole or part of any Service in the event of any suspected illegal
              activity, extended periods of inactivity, or requests by law
              enforcement or other government agencies. Objections to suspension
              or disabling of user accounts should be made to
              <a
                href="mailto:dpo@smartsensesolutions.com"
                class="foster-color"
              >
                dpo@smartsensesolutions.com
              </a>
              within thirty days of being notified about the suspension. We may
              terminate a suspended or disabled user account after thirty days.
              We will also terminate your user account at your request.
            </p>
            <p>
              In addition, we reserve the right to terminate your user account
              and deny the Services upon reasonable belief that you have
              violated the Agreement and to terminate your access to any Beta
              Service in case of unexpected technical issues or discontinuation
              of the Beta Service. You have the right to terminate your user
              account if theSense breaches its obligations under this Agreement
              and in such event, you will be entitled to prorated refund of any
              prepaid fees. Termination of the user account will include denial
              of access to all Services, deletion of information in your user
              account such as your email address and password, and deletion of
              all data in your user account.
            </p>

            <h2>Modification of Terms of Service</h2>
            <p>
              We may modify this Agreement upon notice to you at any time
              through a service announcement or by sending an email to your
              primary email address. If we make significant changes to the
              Agreement that affect your rights, you will be provided with at
              least 30 days advance notice of the changes by email to your
              primary email address. You may terminate your use of the Services
              by providing theSense notice by email within 30 days of being
              notified of the availability of the modified Agreement if the
              Agreement is modified in a manner that substantially affects your
              rights in connection with the use of the Services. In the event of
              such termination, you will be entitled to prorated refund of the
              unused portion of any prepaid fees. Your continued use of the
              Service after the effective date of any change to the Agreement
              will be deemed to be your agreement to the modified Agreement.
            </p>

            <h2>End Of Terms Of Service</h2>
            <p>
              If you have any questions or concerns regarding this Agreement,
              please contact us at
              <a
                href="mailto:dpo@smartsensesolutions.com"
                class="foster-color"
              >
                dpo@smartsensesolutions.com
              </a>
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default TermsOfService;
